import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/Email';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useNotifications } from '../store/notifications/useNotifications';

export const GetMainListItems = ({ history }) => {
  const { setNotification } = useNotifications();
  return (
    <div>
      <ListItem
        key="route1"
        button
        onClick={() => {
          history.push('/groups');
        }}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Groups" />
      </ListItem>
      <ListItem
        key="route2"
        button
        onClick={() => {
          setNotification(undefined);
          history.push('/notifications');
        }}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="New notifiation" />
      </ListItem>
      <ListItem
        key="route3"
        button
        onClick={() => {
          history.push('/');
        }}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="History notifications" />
      </ListItem>
    </div>
  );
};
