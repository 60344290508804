/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import { useCompanies } from '../../store/companies/useGroups';
import { VirtualizedTable } from './VirtualizedTable';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function UserSelectTransferList({ onChange, value, filter }) {
  const classes = useStyles();
  const { companies, getCompanies } = useCompanies();
  useEffect(() => {
    getCompanies();
  }, []);

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(value ? value : []);
  const [right, setRight] = React.useState([]);

  useEffect(() => {
    if (companies)
      setRight(
        companies
          .map((company) => ({ id: company.id, name: company.name }))
          .filter((user) => !left.some((u) => u.id === user.id))
      );
  }, [companies]);
  useEffect(() => {
    onChange(left);
  }, [left]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleAllRight = () => {
    setRight(right.concat(left.filter((r) => r.name.toLowerCase().includes(filter.toLowerCase()))));
    setLeft(left.filter((r) => !r.name.toLowerCase().includes(filter.toLowerCase())));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right.filter((r) => r.name.toLowerCase().includes(filter.toLowerCase()))));
    setRight(right.filter((r) => !r.name.toLowerCase().includes(filter.toLowerCase())));
  };

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>
        <VirtualizedTable
          checked={checked}
          users={left}
          filter={filter}
          setChecked={setChecked}
          title="Currently selected"
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right">
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right">
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left">
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left">
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <VirtualizedTable checked={checked} users={right} filter={filter} setChecked={setChecked} title="Available" />
      </Grid>
    </Grid>
  );
}
