import React, { useState, Fragment } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { TextField, Button, Typography, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Dropzone from 'react-dropzone';

import { customStyles, useStyles } from './GroupInfoModal.styles';
import UserSelectTransferList from './UserSelectTransferList';
import { useGroups } from '../../store/groups/useGroups';

export const GroupInfoModal = () => {
  const { closeModal, isModalOpen, addGroup, group, editGroup, companies, bulkAdd, isLoading } = useGroups();
  const { handleSubmit, register, errors, setValue, getValues } = useForm();
  const { closeButton, actionButton, textField, flexContainer, text, appBar, filterField, dropzone } = useStyles();
  const [filter, setFilter] = useState('');
  const [method, setMethod] = useState('manual');
  return (
    <Modal
      isOpen={isModalOpen}
      onAfterClose={() => {
        setFilter('');
        setMethod('manual');
      }}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal">
      <AppBar className={appBar}>
        <Button
          className={closeButton}
          edge="end"
          onClick={() => {
            closeModal();
          }}>
          <CloseIcon />
        </Button>
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          {!group.id ? 'Add group' : 'Edit group'}
        </Typography>
      </AppBar>

      <form
        onSubmit={handleSubmit(() => {
          const values = getValues();
          if (method === 'file') values.companies = companies;

          if (!group.id) addGroup(values);
          else editGroup({ ...values, id: group.id });

          closeModal();
        })}>
        <RHFInput
          as={
            <TextField
              name="name"
              label="Group name"
              error={!!errors.name}
              helperText={!!errors.name && 'Please fill the group name'}
              className={textField}
            />
          }
          rules={{ required: true }}
          name="name"
          register={register}
          setValue={setValue}
          defaultValue={group.name}
        />
        <RHFInput
          as={<TextField multiline name="description" label="Group description" className={filterField} />}
          name="description"
          register={register}
          setValue={setValue}
          defaultValue={group.description}
        />
        {method === 'manual' && (
          <Fragment>
            <div className={flexContainer}>
              <Typography className={text}>Select companies:</Typography>
              <TextField
                className={filterField}
                label="Filter companies"
                onChange={(event) => {
                  setFilter(event.target.value);
                }}
              />
            </div>
            <RHFInput
              as={<UserSelectTransferList filter={filter} />}
              rules={{ required: true }}
              name="companies"
              register={register}
              setValue={setValue}
              defaultValue={group.companies}
            />
          </Fragment>
        )}
        {method === 'file' && (
          <Dropzone
            onDrop={(acceptedFiles) => {
              bulkAdd(acceptedFiles[0]);
            }}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"></link>
                <div {...getRootProps()} className={dropzone}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop a file with company codes, or click to select it</p>
                  {companies.length > 0 && (
                    <ul className="w3-ul">
                      <li>
                        <h4>Selected companies:</h4>
                      </li>
                      {companies.map((c) => (
                        <li key={`com_${c.id}`}>{c.name}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        )}
        <ToggleButtonGroup
          value={method}
          exclusive
          onChange={(_, newMethod) => {
            if (!!newMethod) setMethod(newMethod);
          }}
          aria-label="text alignment">
          <ToggleButton value="manual" aria-label="justified">
            Manual
          </ToggleButton>
          <ToggleButton value="file" aria-label="left aligned">
            File
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          disabled={isLoading}
          edge="end"
          variant="contained"
          color="primary"
          type="submit"
          className={actionButton}>
          {!isLoading ? !group.id ? 'add' : 'save' : <CircularProgress size={30} color="secondary" />}
        </Button>
      </form>
    </Modal>
  );
};
