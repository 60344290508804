import { useDispatch, useSelector } from 'react-redux';

import { createAction } from '../createAction';
import {
  GET_GROUPS,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_GROUP,
  ADD_GROUP,
  SAVE_GROUP,
  DELETE_GROUP,
  BULK_ADD
} from './consts';

export const useGroups = () => {
  const dispatch = useDispatch();
  const { groups, isModalOpen, group, companies, isLoading } = useSelector((state) => state.groups);

  const getGroups = () => dispatch(createAction(GET_GROUPS));
  const openModal = () => dispatch(createAction(OPEN_MODAL));
  const closeModal = () => dispatch(createAction(CLOSE_MODAL));
  const setGroup = (group) => dispatch(createAction(SET_GROUP, group));
  const addGroup = (group) => dispatch(createAction(ADD_GROUP, group));
  const editGroup = (group) => dispatch(createAction(SAVE_GROUP, group));
  const deleteGroup = (groupId) => dispatch(createAction(DELETE_GROUP, groupId));
  const bulkAdd = (file) => dispatch(createAction(BULK_ADD, file));

  return {
    group,
    groups,
    isModalOpen,
    companies,
    isLoading,
    bulkAdd,
    addGroup,
    getGroups,
    openModal,
    closeModal,
    setGroup,
    editGroup,
    deleteGroup
  };
};
