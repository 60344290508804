import React from 'react';
import { Provider } from 'react-redux';

import Dashboard from './components/Dashboard';

import store from './store';
import { BrowserRouter } from 'react-router-dom';

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Dashboard />
    </BrowserRouter>
  </Provider>
);

export default App;
