import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_URL}api/v1`;
export const apiCaller = {
  sendNotification: (notification) =>
    axios.post(`${baseUrl}/notifications`, notification, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }),
  getHistoryNotifications: (page, perPage, filter) =>
    axios.get(`${baseUrl}/notifications/admin/history?page=${page}&perPage=${perPage}&filter=${filter}`, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }),
  getCompanies: () =>
    axios.get(`${baseUrl}/dev-utils/companies`, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }),
  getGroups: () => axios.get(`${baseUrl}/groups`),
  getUsers: (filter) => axios.get(`${baseUrl}/users?filter=${filter}`),
  addGroup: (group) =>
    axios.post(
      `${baseUrl}/groups`,
      { ...group, companies: group.companies.map((user) => user.id) },
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }
    ),
  bulkAddCompanies: (file) =>
    axios.post(`${baseUrl}/groups/bulk`, file, {
      // eslint-disable-next-line no-template-curly-in-string
      headers: { 'Content-Type': 'multipart/form-data; boundary=${form._boundary}' }
    }),
  updateName: (group) =>
    axios.patch(
      `${baseUrl}/groups/${group.id}/name`,
      { name: group.name },
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }
    ),
  updateDescription: (group) =>
    axios.patch(
      `${baseUrl}/groups/${group.id}/description`,
      { description: group.description },
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }
    ),
  updateCompanies: (group) =>
    axios.put(
      `${baseUrl}/groups/${group.id}/companies`,
      { companies: group.companies.map((u) => u.id) },
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }
    ),
  deleteGroup: (id) =>
    axios.delete(`${baseUrl}/groups/${id}`, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
};
