import { GET_COMPANIES, GET_COMPANIES_FAILED, GET_COMPANIES_SUCCESS } from './consts';

const initialState = {
  isLoading: false,
  companies: []
};

export const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        isLoading: true
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companies: action.payload
      };
    case GET_COMPANIES_FAILED:
      return {
        ...state,
        isLoading: false,
        companies: []
      };
    default:
      return state;
  }
};
