import { AuthenticationContext } from 'react-adal';

const env = process.env;

const getAdalConfig = () => {
  const config = {
    tenant: env.REACT_APP_AZURE_TENANT,
    clientId: env.REACT_APP_AZURE_CLIENT,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    cacheLocation: 'sessionStorage',
    endpoints: []
  };
  return config;
};

export const getAuthContext = () => new AuthenticationContext(getAdalConfig());
export const getToken = () => getAuthContext().getCachedToken(getAuthContext().config.clientId);
