import React from 'react';
import Modal from 'react-modal';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { runWithAdal } from 'react-adal';
import { getAuthContext, getToken } from './services/auth';
import axios from 'axios';

const root = document.getElementById('root');

runWithAdal(
  getAuthContext(),
  () => {
    const onRequestSuccess = (config) => {
      const token = getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    };
    axios.interceptors.request.use(onRequestSuccess);

    ReactDOM.render(<App />, root);
    Modal.setAppElement('#root');
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  },
  false
);
