import { useDispatch, useSelector } from 'react-redux';

import { createAction } from '../createAction';
import {
  SEND_NOTIFICATION,
  GET_HISTORY_NOTIFICATION,
  HISTORY_NOTIFICATION_SET_PAGE,
  HISTORY_NOTIFICATION_SET_FILTER,
  HISTORY_NOTIFICATION_SET_NOTIFICATION
} from './consts';

export const useNotifications = () => {
  const dispatch = useDispatch();
  const { notifications, page, perPage, filter, total, notification, isLoading } = useSelector(
    (state) => state.notifications
  );

  const sendNotification = (notification) => dispatch(createAction(SEND_NOTIFICATION, notification));
  const getNotifications = () => dispatch(createAction(GET_HISTORY_NOTIFICATION, { page, perPage, filter }));
  const setPage = (newPage) => {
    dispatch(createAction(HISTORY_NOTIFICATION_SET_PAGE, newPage));
    dispatch(createAction(GET_HISTORY_NOTIFICATION, { page: newPage, perPage, filter }));
  };
  const setFilter = (newFilter) => {
    dispatch(createAction(HISTORY_NOTIFICATION_SET_FILTER, newFilter));
    dispatch(createAction(GET_HISTORY_NOTIFICATION, { page, perPage, filter: newFilter }));
  };
  const setNotification = (notification) => dispatch(createAction(HISTORY_NOTIFICATION_SET_NOTIFICATION, notification));

  return {
    notifications,
    notification,
    page,
    perPage,
    total,
    isLoading,
    setNotification,
    sendNotification,
    getNotifications,
    setPage,
    setFilter
  };
};
