import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_GROUPS,
  GET_GROUPS_FAILED,
  GET_GROUPS_SUCCESS,
  ADD_GROUP,
  SAVE_GROUP,
  DELETE_GROUP,
  BULK_ADD,
  BULK_ADD_SUCCESS,
  BULK_ADD_FAILED,
  GROUP_SAVING_FINISHED
} from './consts';
import { createAction } from '../createAction';

import { apiCaller } from '../../services/api-caller';
import { toast } from 'react-toastify';

export function* getGroups() {
  try {
    const response = yield call(apiCaller.getGroups);
    yield put(createAction(GET_GROUPS_SUCCESS, response.data.groups));
  } catch (error) {
    yield put(createAction(GET_GROUPS_FAILED));
  }
}

export function* addGroup(group) {
  try {
    yield call(apiCaller.addGroup, group.payload);
    yield put(createAction(GET_GROUPS));
    yield put(createAction(GROUP_SAVING_FINISHED));
    toast.success('Group has been added');
  } catch (error) {
    yield put(createAction(GROUP_SAVING_FINISHED));
    toast.error('Group save failed');
  }
}

export function* saveGroup(group) {
  try {
    yield call(apiCaller.updateName, group.payload);
    yield call(apiCaller.updateDescription, group.payload);
    yield call(apiCaller.updateCompanies, group.payload);
    yield put(createAction(GET_GROUPS));
    yield put(createAction(GROUP_SAVING_FINISHED));
    toast.success('Group has been saved');
  } catch (error) {
    yield put(createAction(GROUP_SAVING_FINISHED));
    toast.error('Group save failed');
  }
}

export function* bulkAdd(file) {
  try {
    const formData = new FormData();
    formData.append('file', file.payload);
    const response = yield call(apiCaller.bulkAddCompanies, formData);
    yield put(createAction(BULK_ADD_SUCCESS, response.data.companies));
    toast.success('Companies import successfull');
  } catch (error) {
    yield put(createAction(BULK_ADD_FAILED));
    toast.success('Companies import failed');
  }
}

export function* deleteGroup(action) {
  try {
    yield call(apiCaller.deleteGroup, action.payload);
    yield put(createAction(GET_GROUPS));
    toast.success('Group has been deleted');
  } catch (error) {
    toast.error('Group deletion failed');
  }
}

export function* groupsRootSaga() {
  yield takeLatest(GET_GROUPS, getGroups);
  yield takeLatest(ADD_GROUP, addGroup);
  yield takeLatest(SAVE_GROUP, saveGroup);
  yield takeLatest(DELETE_GROUP, deleteGroup);
  yield takeLatest(BULK_ADD, bulkAdd);
}
