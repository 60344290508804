import { combineReducers } from 'redux';

import { groupsReducer } from './groups/reducer';
import { companiesReducer } from './companies/reducer';
import { notificationsReducer } from './notifications/reducer';
import { usersReducer } from './users/reducer';

export default combineReducers({
  groups: groupsReducer,
  companies: companiesReducer,
  notifications: notificationsReducer,
  users: usersReducer
});
