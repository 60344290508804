import { takeLatest, call, put } from 'redux-saga/effects';

import { GET_COMPANIES, GET_COMPANIES_FAILED, GET_COMPANIES_SUCCESS } from './consts';
import { createAction } from '../createAction';

import { apiCaller } from '../../services/api-caller';

export function* getCompanies() {
  try {
    const response = yield call(apiCaller.getCompanies);
    yield put(createAction(GET_COMPANIES_SUCCESS, response.data));
  } catch (error) {
    yield put(createAction(GET_COMPANIES_FAILED));
  }
}

export function* companiesRootSaga() {
  yield takeLatest(GET_COMPANIES, getCompanies);
}
