export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILED = 'GET_GROUPS_FAILED';

export const ADD_GROUP = 'ADD_GROUP';
export const SAVE_GROUP = 'SAVE_GROUP';
export const GROUP_SAVING_FINISHED = 'GROUP_SAVING_FINISHED';
export const DELETE_GROUP = 'DELETE_GROUP';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const BULK_ADD = 'BULK_ADD';
export const BULK_ADD_SUCCESS = 'BULK_ADD_SUCCESS';
export const BULK_ADD_FAILED = 'BULK_ADD_FAILED';
export const BULK_ADD_REFRESH = 'BULK_ADD_REFRESH';

export const SET_GROUP = 'SET_GROUP';
