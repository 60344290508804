import { useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import dedent from 'dedent';
export const useDraftAndHtmlEditor = () => {
  const [html, setHtml] = useState('');
  const [draft, setDraft] = useState(EditorState.createEmpty());
  const handleHtmlChange = (html) => {
    setHtml(dedent(html));
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    setDraft(editorState);
  };
  const handleDraftChange = (draft) => {
    const raw = convertToRaw(draft.getCurrentContent());

    setHtml(dedent(draftToHtml(raw)));
    setDraft(draft);
  };
  return { html, draft, handleHtmlChange, handleDraftChange };
};
