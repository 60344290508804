import { all, fork } from 'redux-saga/effects';

import { groupsRootSaga } from './groups/sagas';
import { companiesRootSaga } from './companies/sagas';
import { notificationsRootSaga } from './notifications/sagas';
import { usersRootSaga } from './users/sagas';

const sagas = [groupsRootSaga, companiesRootSaga, notificationsRootSaga, usersRootSaga];

export default function* rootSaga() {
  yield all(sagas.map((saga) => fork(saga)));
}
