import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const Header = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  box-sizing: border-box;
  list-style: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
`;

const useStyles = makeStyles(() => ({
  paper: {
    width: '60vh',
    height: 230,
    overflow: 'auto'
  }
}));

export { Header, useStyles };
