import { useDispatch, useSelector } from 'react-redux';

import { createAction } from '../createAction';
import { GET_USERS } from './consts';

export const useUsers = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);

  const getUsers = (filter) => dispatch(createAction(GET_USERS, filter));

  return {
    getUsers,
    users
  };
};
