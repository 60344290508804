import { useDispatch, useSelector } from 'react-redux';

import { createAction } from '../createAction';
import { GET_COMPANIES } from './consts';

export const useCompanies = () => {
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state.companies);

  const getCompanies = () => dispatch(createAction(GET_COMPANIES));

  return {
    companies,
    getCompanies
  };
};
