/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useStyles } from './Dashboard.styles';
import { GetMainListItems } from '../MenuListItems';
import GroupPanel from '../Group';

import { Switch, Route, withRouter } from 'react-router-dom';
import { NewNotificationPanel } from '../NewNotificationPanel';
import NotificationsPanel from '../NotificationsPanel';
import { useNotifications } from '../../store/notifications/useNotifications';
import { useEffect } from 'react';
import { Toast } from '../../ToastContainer';

const Dashboard = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const history = props.history;
  const { setNotification } = useNotifications();
  useEffect(() => {
    setNotification(undefined);
  }, []);

  return (
    <div className={classes.root}>
      <Toast />
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            CIR - WebAdmin
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <GetMainListItems history={history} />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route path="/notifications">
              <NewNotificationPanel />
            </Route>
            <Route path="/groups">
              <GroupPanel />
            </Route>
            <Route path="/" exact>
              <NotificationsPanel history={history} />
            </Route>
          </Switch>
        </Container>
      </main>
    </div>
  );
};

const DashboardComponent = withRouter(Dashboard);
export { DashboardComponent };
