import { takeLatest, call, put } from 'redux-saga/effects';

import { GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAILED } from './consts';
import { createAction } from '../createAction';

import { apiCaller } from '../../services/api-caller';

export function* getUsers(action) {
  try {
    const response = yield call(apiCaller.getUsers, action.payload);
    yield put(createAction(GET_USERS_SUCCESS, response.data.users));
  } catch (error) {
    yield put(createAction(GET_USERS_FAILED));
  }
}

export function* usersRootSaga() {
  yield takeLatest(GET_USERS, getUsers);
}
