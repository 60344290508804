import {
  GET_GROUPS,
  GET_GROUPS_FAILED,
  GET_GROUPS_SUCCESS,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_GROUP,
  SAVE_GROUP,
  BULK_ADD,
  BULK_ADD_SUCCESS,
  BULK_ADD_FAILED,
  BULK_ADD_REFRESH,
  GROUP_SAVING_FINISHED,
  ADD_GROUP
} from './consts';
import _ from 'lodash';

const initialState = {
  isLoading: false,
  groups: [],
  isModalOpen: false,
  group: {},
  companies: []
};

export const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUPS:
      return {
        ...state,
        isLoading: true
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: action.payload
      };
    case GET_GROUPS_FAILED:
      return {
        ...state,
        isLoading: false,
        groups: []
      };
    case OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        companies: [],
        group: {}
      };
    case SET_GROUP:
      return {
        ...state,
        group: _.isEmpty(action.payload) ? { users: [] } : action.payload
      };
    case SAVE_GROUP:
      return {
        ...state,
        isLoading: true,
        group: {}
      };
    case ADD_GROUP:
      return {
        ...state,
        isLoading: true
      };
    case BULK_ADD:
      return {
        ...state,
        companies: [],
        isLoading: true
      };
    case BULK_ADD_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        isLoading: false
      };
    case BULK_ADD_FAILED:
      return {
        ...state,
        companies: [],
        isLoading: false
      };
    case BULK_ADD_REFRESH:
      return {
        ...state,
        companies: []
      };
    case GROUP_SAVING_FINISHED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
