import React from 'react';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Header, useStyles } from './VirtualizedTable.styles';

const VirtualizedTable = ({ users, title, filter, checked, setChecked }) => {
  const classes = useStyles();
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  var filteredUsers = users.filter((user) => user.name.toLowerCase().includes(filter.toLowerCase()));

  return (
    <Paper className={classes.paper}>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={48}
            gridStyle={{
              direction: 'inherit'
            }}
            headerHeight={48}
            rowGetter={({ index }) => filteredUsers[index]}
            rowCount={filteredUsers.length}>
            <Column
              key="col-key"
              width={100}
              headerRenderer={() => <Header>{title}</Header>}
              cellRenderer={(cell) => {
                const labelId = `transfer-list-item-${cell.rowData.id}-label`;
                return (
                  <ListItem key={cell.rowData.id} role="listitem" button onClick={handleToggle(cell.rowData)}>
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(cell.rowData) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={cell.rowData.name} />
                  </ListItem>
                );
              }}
              dataKey="col-key"
            />
          </Table>
        )}
      </AutoSizer>
    </Paper>
  );
};

export { VirtualizedTable };
