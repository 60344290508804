import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';
import logger from 'redux-logger';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store =
    process.env.NODE_ENV === 'development'
      ? createStore(rootReducer, applyMiddleware(sagaMiddleware, logger))
      : createStore(rootReducer, applyMiddleware(sagaMiddleware));

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore();
