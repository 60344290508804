import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  fixedAddButton: {
    position: 'absolute',
    bottom: '4rem',
    right: '4rem'
  },
  content: {
    padding: '1rem'
  }
}));
export { useStyles };
