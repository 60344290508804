export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILED = 'SEND_NOTIFICATION_FAILED';

export const GET_HISTORY_NOTIFICATION = 'GET_HISTORY_NOTIFICATION';
export const GET_HISTORY_NOTIFICATION_SUCCESS = 'GET_HISTORY_NOTIFICATION_SUCCESS';
export const GET_HISTORY_NOTIFICATION_FAILED = 'GET_HISTORY_NOTIFICATION_FAILED';

export const HISTORY_NOTIFICATION_SET_PAGE = 'HISTORY_NOTIFICATION_SET_PAGE';
export const HISTORY_NOTIFICATION_SET_FILTER = 'HISTORY_NOTIFICATION_SET_FILTER';

export const HISTORY_NOTIFICATION_SET_NOTIFICATION = 'HISTORY_NOTIFICATION_SET_NOTIFICATION';
