/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from '@material-ui/icons/Search';
import {
  Grid,
  ListItemText,
  ListItem,
  Divider,
  List,
  makeStyles,
  ListItemSecondaryAction,
  Button,
  Paper,
  InputBase,
  IconButton
} from '@material-ui/core';
import { useEffect } from 'react';
import { useNotifications } from '../store/notifications/useNotifications';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: '3rem',
    width: '90%'
  },
  inline: {
    display: 'inline'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  fixedAddButton: {
    position: 'absolute',
    bottom: '4rem',
    right: '4rem'
  },
  content: {
    padding: '1rem'
  },
  iconButton: {
    float: 'right',
    padding: 10
  },
  pagination: {
    float: 'right',
    padding: '1rem 0'
  }
}));

const NotificationsPanel = ({ history }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const { getNotifications, setPage, notifications, total, perPage, setNotification, setFilter } = useNotifications();
  const pagesRest = total % perPage;
  let pages = (total - pagesRest) / perPage;
  pages = pagesRest > 0 ? pages + 1 : pages;

  useEffect(() => {
    getNotifications();
  }, []);
  const [filterTemt, setFilterTemt] = useState('');

  return (
    <Fragment>
      <Paper className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Filter notifications"
          inputProps={{ 'aria-label': 'filter nitifications' }}
          onChange={(e) => {
            setFilterTemt(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              setFilter(filterTemt);
            }
          }}
        />
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          onClick={() => {
            setFilter(filterTemt);
          }}>
          <SearchIcon />
        </IconButton>
      </Paper>
      <Divider />
      <List className={classes.root}>
        {notifications.map((notification) => (
          <NotificationListItem
            history={history}
            notification={notification}
            key={`notification-list-item-${notification.id}`}
            setNotification={(notification) => {
              setNotification(notification);
            }}
          />
        ))}
      </List>
      <Pagination
        className={classes.pagination}
        count={pages}
        variant="outlined"
        shape="rounded"
        onChange={(_, pageNumber) => {
          setPage(pageNumber);
        }}
      />
    </Fragment>
  );
};

const NotificationListItem = ({ notification, setNotification, history }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <ListItem button alignItems="flex-start">
        <Grid className={classes.content} container spacing={3}>
          <Grid item xs={8}>
            <ListItemText primary={notification.title} secondary={notification.description} />
          </Grid>
          <Grid item xs={2}>
            <ListItemText edge="end" primary={`Created: ${new Date(notification.createdAt).toLocaleDateString()}`} />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <ListItemSecondaryAction>
            <Button
              edge="end"
              onClick={() => {
                history.push('/notifications');
                setNotification(notification);
              }}>
              <EmailIcon />
            </Button>
          </ListItemSecondaryAction>
        </Grid>
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </Fragment>
  );
};

export default NotificationsPanel;
