import React from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Toast = () => {
  return (
    <ToastContainer
      transition={Zoom}
      newestOnTop={true}
      position="top-right"
      autoClose={3000}
      hideProgressBar={true}
      closeOnClick={true}
      pauseOnHover={true}
    />
  );
};

export { Toast };
