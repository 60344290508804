/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useGroups } from '../store/groups/useGroups';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { TextField, Button, makeStyles, Typography, FormControlLabel, CircularProgress } from '@material-ui/core';
import ReactSelect from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNotifications } from '../store/notifications/useNotifications';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import Code from '@material-ui/icons/Code';
import CodeEditor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-jsx';
import { useDraftAndHtmlEditor } from '../services/useDraftAndHtmlEditor';
import { useUsers } from '../store/users/useUsers';
import * as yup from 'yup';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    float: 'right'
  },
  actionButton: {
    float: 'right',
    height: '2.5rem'
  },
  actionButtonMargin: {
    'margin-right': '0.5rem'
  },
  textField: {
    display: 'flex',
    margin: '0.5rem'
  },
  select: {
    width: '100%',
    padding: '5px'
  },
  flexContainer: {
    display: 'flex'
  },
  text: {
    width: '10rem',
    alignSelf: 'center'
  },
  editor: { minHeight: '20rem', padding: '1rem' },
  content: {
    background: 'white'
  },
  title: {
    background: '#3f51b5',
    color: '#fff',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    padding: '6px'
  }
}));

const reactSelectTheme = (error) => (theme) => {
  const errorStyling = error
    ? {
        primary: 'red',
        neutral10: 'red',
        neutral30: 'red',
        neutral20: 'red',
        neutral60: 'red'
      }
    : {};

  return {
    ...theme,
    colors: {
      ...theme.colors,
      ...errorStyling
    }
  };
};

const types = [
  { label: 'Alert', value: 1 },
  { label: 'Advisory', value: 2 },
  { label: 'Incident', value: 3 },
  { label: 'Update', value: 4 }
];

const checkoutAddressSchema = yup.object().shape({
  groups: yup
    .array()
    .nullable()
    .test(function (value) {
      const { users, sendAll } = this.parent;
      return sendAll || (users && users.length > 0) || (value && value.length > 0);
    }),
  users: yup
    .array()
    .nullable()
    .test(function (value) {
      const { groups, sendAll } = this.parent;
      return sendAll || (groups && groups.length > 0) || (value && value.length > 0);
    }),
  type: yup.object().required(),
  title: yup.string().required(),
  description: yup.string().required()
});

export const NewNotificationPanel = () => {
  const { group, getGroups, groups } = useGroups();
  const { handleSubmit, register, errors, setValue, getValues } = useForm({ validationSchema: checkoutAddressSchema });
  const { actionButton, textField, title, select, content, editor } = useStyles();
  const [method, setMethod] = useState('text');
  const { sendNotification, notification, isLoading } = useNotifications();
  const { html, draft, handleHtmlChange, handleDraftChange } = useDraftAndHtmlEditor();
  const { users, getUsers } = useUsers();
  const [sendAll, setSendAll] = useState(false);
  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (notification && groups) {
      const { description, title, type, sendAll } = notification;
      console.log(notification);
      setValue('description', description);
      setValue('title', title);
      setValue(
        'type',
        types.find((t) => t.label === type)
      );
      setValue(
        'groups',
        groups.filter((g) => notification.groups.includes(g.id))
      );
      setValue('users', notification.users);
      handleHtmlChange(notification.html);
      setSendAll(sendAll);
    } else {
      setValue('description', '');
      setValue('title', '');
      setValue('type', undefined);
      setValue('groups', []);
      setValue('users', []);
      handleHtmlChange('');
    }
  }, [notification, groups]);
  const getUsersByFilter = (filter) => {
    if (filter) getUsers(filter);
  };

  return (
    <div className={content}>
      <div className={title}>
        <Typography component="h2" variant="h5" color="inherit" noWrap>
          Create new notification
        </Typography>
      </div>

      <form
        onSubmit={handleSubmit(() => {
          const { groups, users, type, sendAll, ...rest } = getValues();
          const notification = {
            ...rest,
            type: type.value,
            groups: !!groups ? groups.map((g) => g.id) : [],
            users: !!users ? users.map((u) => u.id) : [],
            html: html,
            sendToAll: sendAll
          };
          sendNotification(notification);
        })}>
        <RHFInput
          as={
            <TextField
              name="title"
              label="Title"
              error={!!errors.title}
              helperText={!!errors.title && 'Please fill the title'}
              className={textField}
            />
          }
          rules={{ required: true }}
          name="title"
          register={register}
          setValue={setValue}
          defaultValue={group.name}
        />
        <RHFInput
          as={
            <ReactSelect
              theme={reactSelectTheme(errors.type)}
              name="type"
              placeholder="Select type"
              className={select}
              options={types}
            />
          }
          name="type"
          register={register}
          setValue={setValue}
        />
        <RHFInput
          as={
            <FormControlLabel
              labelPlacement="start"
              control={<Checkbox color="primary" checked={sendAll} name="sendAll" />}
              label="Send to all"
              name="sendAll"
            />
          }
          name="sendAll"
          register={register}
          setValue={(name, _value, trigger) => {
            setValue(name, !sendAll, trigger);
            setSendAll(!sendAll);
          }}
        />
        <RHFInput
          as={
            <ReactSelect
              isMulti
              theme={reactSelectTheme(errors.groups)}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              name="groups"
              placeholder="Select groups"
              className={select}
              options={groups}
              isDisabled={sendAll}
            />
          }
          name="groups"
          register={register}
          setValue={setValue}
        />
        <RHFInput
          as={
            <ReactSelect
              isMulti
              onInputChange={(input) => {
                getUsersByFilter(input);
              }}
              theme={reactSelectTheme(errors.users)}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.email}
              name="users"
              placeholder="Select users"
              className={select}
              options={users}
              isDisabled={sendAll}
            />
          }
          name="users"
          register={register}
          setValue={setValue}
        />
        <RHFInput
          as={
            <TextField
              multiline
              name="description"
              error={!!errors.description}
              helperText={!!errors.description && 'Please fill the description'}
              label="Description"
              className={textField}
            />
          }
          name="description"
          register={register}
          setValue={setValue}
          rules={{ required: true }}
          defaultValue={group.description}
        />
        {method === 'text' && (
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorState={draft}
            editorClassName={editor}
            onEditorStateChange={handleDraftChange}
          />
        )}
        {method === 'code' && (
          <div className={editor}>
            <CodeEditor
              value={html}
              onValueChange={handleHtmlChange}
              highlight={(code) => highlight(code, languages.jsx)}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                codeEditor: {
                  minHeight: '20em'
                }
              }}
            />
          </div>
        )}
        <ToggleButtonGroup
          value={method}
          exclusive
          onChange={(_, newMethod) => {
            if (!!newMethod) setMethod(newMethod);
          }}
          aria-label="text alignment">
          <ToggleButton value="text" aria-label="justified">
            <FormatAlignJustifyIcon />
          </ToggleButton>
          <ToggleButton value="code" aria-label="left aligned">
            <Code />
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          disabled={isLoading}
          edge="end"
          variant="contained"
          color="primary"
          type="submit"
          className={actionButton}>
          {!isLoading ? 'Send' : <CircularProgress size={30} color="secondary" />}
        </Button>
      </form>
    </div>
  );
};
