import {
  GET_HISTORY_NOTIFICATION,
  GET_HISTORY_NOTIFICATION_SUCCESS,
  GET_HISTORY_NOTIFICATION_FAILED,
  HISTORY_NOTIFICATION_SET_PAGE,
  HISTORY_NOTIFICATION_SET_FILTER,
  HISTORY_NOTIFICATION_SET_NOTIFICATION,
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILED
} from './consts';

const initialState = {
  page: 1,
  perPage: 10,
  filter: '',
  notifications: [],
  isLoading: false,
  total: 0,
  notification: undefined
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY_NOTIFICATION:
      return {
        ...state,
        isLoading: true
      };
    case HISTORY_NOTIFICATION_SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };
    case HISTORY_NOTIFICATION_SET_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    case GET_HISTORY_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notifications: action.payload.data,
        total: action.payload.pagination.total
      };
    case GET_HISTORY_NOTIFICATION_FAILED:
      return {
        ...state,
        isLoading: false,
        users: []
      };
    case HISTORY_NOTIFICATION_SET_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case SEND_NOTIFICATION:
      return {
        ...state,
        isLoading: true
      };
    case SEND_NOTIFICATION_SUCCESS:
    case SEND_NOTIFICATION_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
