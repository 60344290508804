/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PeopleIcon from '@material-ui/icons/People';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import {
  ListItemSecondaryAction,
  Button,
  Grid,
  Collapse,
  ListItemIcon,
  ListItemText,
  ListItem,
  Divider,
  List,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide
} from '@material-ui/core';
import { useStyles } from './GroupPanel.styles';
import { GroupInfoModal } from './GroupInfoModal';
import { useGroups } from '../../store/groups/useGroups';
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const GroupPanel = () => {
  const classes = useStyles();
  const { groups, getGroups, openModal, setGroup } = useGroups();

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <Fragment>
      <List className={classes.root}>
        {groups.map((group) => (
          <GroupListItem group={group} key={`group-list-item-${group.id}`} />
        ))}
      </List>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fixedAddButton}
        onClick={() => {
          setGroup({});
          openModal();
        }}>
        <AddIcon />
      </Fab>
      <GroupInfoModal />
    </Fragment>
  );
};

const GroupListItem = ({ group }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isDialogOpen, setOpenDialog] = useState(false);
  const { openModal, setGroup, deleteGroup } = useGroups();
  return (
    <Fragment>
      <ListItem button alignItems="flex-start">
        <Grid className={classes.content} container spacing={3}>
          <Grid item xs={8}>
            <ListItemText primary={group.name} secondary={group.description} />
          </Grid>
          <Grid item xs={2}>
            <ListItemText edge="end" primary={`Last modified: ${new Date(group.lastModified).toLocaleDateString()}`} />
          </Grid>
          <Grid item xs={2}>
            <ListItemSecondaryAction>
              <Button
                edge="end"
                onClick={() => {
                  setGroup(group);
                  openModal();
                }}>
                <EditIcon />
              </Button>
              <Button
                edge="end"
                onClick={() => {
                  setOpenDialog(true);
                }}>
                <DeleteIcon />
              </Button>
              {!!group.companies.length && (
                <ListItem
                  button
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}>
                  {isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              )}
            </ListItemSecondaryAction>
          </Grid>
        </Grid>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {group.companies.map((user) => (
            <ListItem button className={classes.nested} key={`group-${group.id}-user-${user.id}`}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={user.name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Divider variant="fullWidth" component="li" />
      <Dialog
        open={isDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Group deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Do you want to delete the group '${group.name}'?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              deleteGroup(group.id);
            }}
            color="primary">
            yes
          </Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            color="primary">
            no
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

GroupPanel.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object)
};

export { GroupPanel };
