import { takeLatest, call, put } from 'redux-saga/effects';

import {
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILED,
  GET_HISTORY_NOTIFICATION_SUCCESS,
  GET_HISTORY_NOTIFICATION,
  GET_HISTORY_NOTIFICATION_FAILED
} from './consts';
import { createAction } from '../createAction';

import { apiCaller } from '../../services/api-caller';
import { toast } from 'react-toastify';

export function* sendNotification(action) {
  try {
    yield call(apiCaller.sendNotification, action.payload);
    yield put(createAction(SEND_NOTIFICATION_SUCCESS));
    toast.success('Notification has been sent');
  } catch (error) {
    yield put(createAction(SEND_NOTIFICATION_FAILED));
    toast.error('Error while sending the notification');
  }
}

export function* getHistoryNotifications(action) {
  try {
    const { page, perPage, filter } = action.payload;
    const response = yield call(apiCaller.getHistoryNotifications, page, perPage, filter);
    yield put(createAction(GET_HISTORY_NOTIFICATION_SUCCESS, response.data));
  } catch (error) {
    yield put(createAction(GET_HISTORY_NOTIFICATION_FAILED));
  }
}

export function navigateToHistoryTab() {
  try {
    window.location.replace('/');
  } catch (error) {}
}

export function* notificationsRootSaga() {
  yield takeLatest(SEND_NOTIFICATION, sendNotification);
  yield takeLatest(SEND_NOTIFICATION_SUCCESS, navigateToHistoryTab);
  yield takeLatest(GET_HISTORY_NOTIFICATION, getHistoryNotifications);
}
