import { makeStyles } from '@material-ui/core';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '60em',
    minHeight: '40em'
  }
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    float: 'right'
  },
  actionButton: {
    float: 'right',
    height: '2.5rem'
  },
  actionButtonMargin: {
    'margin-right': '0.5rem'
  },
  textField: {
    width: '100%',
    margin: '2.5rem 0 0.5rem 0'
  },
  filterField: {
    width: '100%'
  },
  flexContainer: {
    display: 'flex'
  },
  text: {
    width: '10rem',
    alignSelf: 'center'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    display: 'block',
    padding: '0.5rem'
  },
  dropzone: {
    background: 'lightgray',
    color: 'white',
    height: '15em',
    fontSize: '20px',
    textAlignLast: 'center'
  }
}));

export { customStyles, useStyles };
